import React from 'react';
import './App.css';
import TreeDiagram from './TreeDiagram';

function App() {
  return (
    <TreeDiagram />
  );
}

export default App;
