
import React, { useState } from 'react';
import { OrganizationChart } from 'primereact/organizationchart';
import { TreeNode } from 'primereact/treenode';
import "primereact/resources/themes/lara-light-indigo/theme.css";     

//core
import "primereact/resources/primereact.min.css";   
export default function ColoredDemo({relations}:any) {
    const [data] = useState<TreeNode[]>([
        relations[0]
     ]);

    const nodeTemplate = (node: TreeNode) => {
        if (node.data?.name) {
            return (
                <div className={node.data.title?`flex flex-column w-36`:`flex flex-column`}>
                    <div className="flex flex-column align-items-center">
                        {node.data.image && (
                        <img alt={node.data.name} src={node.data.image} height={"50px"} width={"50px"} className='rounded'/>
                        )}
                        <span className={node.data.title?`font-bold mb-2`:'mb-1'}>{node.data.name}</span>
                        <span>{node.data.title}</span>
                    </div>
                </div>
            );
        }

        return node.label;
    };

    return (
        <div className="card overflow-x-auto">
            <OrganizationChart value={data} nodeTemplate={nodeTemplate} />
        </div>
    )
}
        