import React, { useEffect, useState } from 'react';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";     
    
//core
import "primereact/resources/primereact.min.css";                                       
import ColoredDemo from './ColoredDemo';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface User {
  id: string;
  email: string;
  name: string;
  image: string;
  manager: string;
  department: string;
  subordinates: User[];
}

const TreeDiagram: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    if (isLoggedIn) {
      fetchData();
    }
  }, [isLoggedIn]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://api.tpp.app/google/relations?token=tpp-ga-rel');
      const data = await response.json();
      setUsers([data]);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const handleLoginSuccess = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('profileObj' in response) {
      const { email } = response.profileObj;
      if (email.endsWith('treeplantingprojects.com')) {
        console.log('Login success:', response);
        setIsLoggedIn(true);
      } else {
        console.error('Invalid email domain');
      }
    }
  };

  const handleLoginFailure = (error: any) => {
    console.error('Login error:', error);
  };


  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      {isLoading ? (
        <p className="text-gray-600 font-bold">Loading...</p>
      ) : isLoggedIn ? (
        <div className="container">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Organization Diagram</h1>
          {users.length && 
          <TransformWrapper
          initialScale={0.4}
          minScale={0.4}
          maxScale={5}
          centerZoomedOut={true}
          zoomAnimation={{
            animationTime: 500
          }}

          // disabled={true}
          >
          <TransformComponent>
            <ColoredDemo relations={users}/>
            </TransformComponent>
    </TransformWrapper>
          }
        </div>
      ) : (
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Login with Google</h1>
          <GoogleLogin
            clientId="517857437085-6hnjreb3rfs0mhtbttefeojtataf1csa.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={handleLoginSuccess}
            onFailure={handleLoginFailure}
            cookiePolicy="single_host_origin"
          />
        </div>
      )}
    </div>
  );
};

export default TreeDiagram;
